import React, { Component } from "react"
import styled from "styled-components"

const AnnotationWrapper = styled.span`
  .annotation-wrapper{
    display: inline-block;
    cursor: help;
    text-indent: 0;
  }
  span.annotation{
    display: inline-block;
    position: relative;
  }
`;

export default class Annotate extends Component {

    onMouseMove = (e) => {
      // pass state change up the tree
      this.props.setAnnotation(this.props.message);
      this.props.setAnnotationAlert(true);

    }

    onMouseLeave = (e) => {

      this.props.setAnnotationAlert(false);

    }

    render() {
        
        const { mark } = this.props;
  
        return (
            <AnnotationWrapper onMouseMove={this.onMouseMove.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)} className="annotation-wrapper">
                <span className="annotation">{ mark }</span>
            </AnnotationWrapper>
        );
    }
}

