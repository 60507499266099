import React, { useState } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import AboutContent from "../components/about/AboutContent"
import Contact from "../components/Contact"


const About = ({ location }) => {
  // Declare a new state variable, which we'll call "count"
  const [annotation, setAnnotation] = useState(false);
  const [annotationAlert, setAnnotationAlert] = useState(false);

  return(
    <Layout location={location} annotation={annotation} setAnnotation={setAnnotation} annotationAlert={annotationAlert} setAnnotationAlert={setAnnotationAlert}>
      <SEO title="About" description="A San Diego based web developer and designer building Shopify ecommerce projects that produce results through memorable digtial experiences."/>
      <AboutContent />
      <Contact setAnnotation={setAnnotation} setAnnotationAlert={setAnnotationAlert}/>
    </Layout>
  )
}

export default About
