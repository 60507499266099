import React from 'react'
import styled from "styled-components"
// import Img from "gatsby-image"
import { Link } from "gatsby"

// const ABOUT_CONTENT_QUERY = graphql`
//     query AboutContent {
//         aboutContentJson {
//             referral_url
//         }
//         allPlatformsJson {
//             edges {
//                 node {
//                     id
//                     name
//                     src {
//                         childImageSharp {
//                             fluid(quality: 100) {
//                                 ...GatsbyImageSharpFluid_withWebp
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }  
// `

const AboutWrapper = styled.article`
    grid-area: auto / 1 / auto / -1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: .5rem;
    grid-row-gap: 1rem;

    header{
        grid-area: 1 / 1 / 1 / -1;
        h1{
            margin: 0;
            font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
            font-weight: 400;
            font-size: .875rem;
            line-height: 1.25;
            text-transform: uppercase;
        }
    }
    section {
        grid-area: auto / 1 / auto / -1;
        p{
            text-indent: calc( calc(100% + .5rem) / 8);
            margin: 0 0 2rem 0;
        }
        > div{
            display: grid;
            grid-template-columns: repeat(6,1fr);
            grid-column-gap: .5rem;
            align-items: center;
            margin: 2rem 0;
            
            > div{
                grid-column: span 2;
            }
        }
    }

    a {
        position: relative;
    }
    a span::after {
        content: '';
        height: 1px;
        width: 0%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #1a1a1a; 
        transition: 0.25s ease width; 
    }
    a:hover span::after {
        width: 100%;
    }

    @media (min-width: 45em){
        grid-column-gap: 1rem;
        header{
            grid-area: 1 / 2 / 1 / -1;
        }
        section{
            grid-area: auto / 2 / auto / 8;
            p{
                text-indent: calc( calc(100% + 1rem) / 6);
                margin: 0 0 2rem 0;
            }
            > div{
                grid-column-gap: 1rem;
            }
        }
    }
    @media (min-width: 60em){
        section{

            > div{
                
                > div{
                    grid-column: span 1;
                }
            }
        }
    }
`;

const AboutContent = () => {

    // const data = useStaticQuery(ABOUT_CONTENT_QUERY)

    return (
        <AboutWrapper>
            <header
            data-sal="fade"
            data-sal-delay="0"
            data-sal-duration="250"
            data-sal-easing="ease-in"
            className="">
                <h1>About</h1>
            </header>
            <section
            data-sal="fade"
            data-sal-delay="0"
            data-sal-duration="500"
            data-sal-easing="ease-in">
                <p>
                    Based in Southern California, I'm a web developer &amp; designer specializing in digital projects for the fashion retail, design &amp; art landscapes. I work to create products that reflect the visual language and design systems of the work they represent, providing consistent experiences for customers across all brand touchpoints. It’s important to me that these produce results through the memorable experiences they create.
                </p>
                <p>
                    By providing innovative, yet concrete solutions that are technology based and connecting brand and user objectives, I am partnering with businesses ready to drive business growth through digital and ecommerce.
                </p>
                <p>
                    Shopify is my ecommerce platform of choice, confident in its ability to provide merchants with the tools they need to grow. As a Shopify Expert since 2016, I provide a range of multidisciplinary services for merchants. I take on projects involving store setup, digital strategy, platform migrations, and bespoke storefront design and development. Interested in starting a store of your own? Get in touch.
                </p>
                
                {/* <div className="platform-list">
                {data.allPlatformsJson.edges.map((edge) => {
    
                    return(
                        <div data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="500"
                        data-sal-easing="ease-in"
                        key={edge.node.id}>
                            <Img fluid={edge.node.src.childImageSharp.fluid} />
                        </div>
                    )
                })}
                </div> */}
                <Link to="/"><span>Back Home</span></Link>
            </section>
        </AboutWrapper>
    )
}

export default AboutContent
