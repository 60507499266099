import React, { Component } from 'react'
import styled from "styled-components"
import Annotate from "./Annotate"

const ContactWrapper = styled.article`
    grid-area: auto / 1 / auto / -1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: .5rem;
    grid-row-gap: 1rem;

    header{
        grid-area: 1 / 1 / 1 / -1;
        h2{
            margin: 0;
            font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
            font-weight: 400;
            font-size: .875rem;
            line-height: 1.25;
            text-transform: uppercase;
        }
    }
    section {
        grid-area: auto / 1 / auto / -1;
        p{
            text-indent: calc( calc(100% + .5rem) / 8);
            margin: 0;
        }
    }
    address {
        grid-area: auto / 1 / auto / -1;
        margin: 0;

        a{
            display: inline-flex;
            align-items: center;
            position: relative;

            span{
                font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
                font-weight: 400;
                font-size: 1rem;
                text-transform: uppercase;
                font-style: normal;
                text-decoration: none;
                position: relative;

                
            }

            svg{
                width: 1.25rem;
            }

            // &::after {
            //     content: '';
            //     height: 2px;
            //     width: 100%;
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     background: #1a1a1a;
            //     transform: scale3d(0, 1, 1);
            //     transform-origin: center right;
            //     transition: transform .75s cubic-bezier(.86,0,.07,.995); 
            // }

            // &:hover:after{
            //     transform-origin: center left;
            //     transform: scale3d(1, 1, 1);
            // }
            
        }
    }
    /*
    h4{
        margin: 0;
        display: inline-block;
        font-style: normal;
        font-size: 5.34vh;
        line-height: 6.67vh;
        white-space: nowrap;

        
    }
    */
   
    .annotation-wrapper{
        text-indent: 0;
        display: inline-block;
        cursor: help;
    }
    span.annotation{
        display: inline-block;
        position: relative;
    }
    @media (min-width: 45em){
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        header{
            grid-area: 1 / 2 / 1 / -1;
        }
        section{
            grid-area: auto / 2 / auto / 8;
            p{
                text-indent: calc( calc(100% + 1rem) / 6);
            }
        }

        address {
            grid-area: auto / 2 / auto / -1;

            a{   
                span{
                    font-size: 1.5rem;
                }  
                svg{
                    width: 1.5rem;
                }
            }
        }
    }
    @media (min-width: 60em){
        h4 {
          font-size: 8vh;
          line-height: 10vh;
        }
    }
    @media (min-aspect-ratio: 8/5){
        h4 {
          font-size: 16vh;
          line-height: 20vh;
        }
    }
`;

export default class Contact extends Component {
  
    componentDidMount() {
        const copyEmailLink = this.contactEmail.current;

        // Copy email to clipboard
        copyEmailLink.onclick = e => {
            e.preventDefault()
            this.copyToClipboard('hello@keiththompson.studio')
            e.target.classList.add('is-copied')
            let content = copyEmailLink.querySelector('span')
            content.innerHTML = 'copied!'
            setTimeout(() => { 
                e.target.classList.remove('is-copied')
                content.innerHTML = 'hello@keiththompson.studio';
             }, 1000)
        }
        
    }
  
    componentWillUnmount() {
    
    }
  
    handleScroll = (e) => {
        //var scrollPos = (window.pageYOffset/(document.body.clientHeight-window.innerHeight))*document.body.clientHeight;
        console.log("scroll")
        //this.state.animations.forEach(animation => {
            //animation.currentTime = scrollPos;
        //});
    }

    copyToClipboard = str => {
        const el = document.createElement('textarea')  // Create a <textarea> element
        el.value = str                                 // Set its value to the string that you want copied
        el.setAttribute('readonly', '')                // Make it readonly to be tamper-proof
        el.style.position = 'absolute'
        el.style.left = '-9999px'                      // Move outside the screen to make it invisible
        document.body.appendChild(el)                  // Append the <textarea> element to the HTML document
        const selected =
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0)     // Store selection if found
            : false                                    // Mark as false to know no selection existed before
        el.select()                                    // Select the <textarea> content
        document.execCommand('copy')                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el)                  // Remove the <textarea> element
        if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges()    // Unselect everything on the HTML document
        document.getSelection().addRange(selected)   // Restore the original selection
        }
    }
    
    constructor(props) {
        super(props);
        this.contactEmail = React.createRef();
    }

    render() {
        const { setAnnotation, setAnnotationAlert } = this.props
        return (
            <ContactWrapper
                data-sal="fade"
                data-sal-delay="0"
                data-sal-duration="500"
                data-sal-easing="ease">
                <header className="">
                    <h2>Contact</h2>
                </header>
                <section>
                    <p>
                        If you're interested in partnering to grow your ecommerce business or have an interesting project you'd like to discuss
                        <Annotate mark="†" message="†Or if you'd just like to say hi." setAnnotation={ setAnnotation } setAnnotationAlert={ setAnnotationAlert }/>
                        , please reach out at the email below.
                    </p>
                </section>
                <address>
                    <a ref={this.contactEmail} href="mailTo:hello@keiththompson.studio" target="_blank" rel="noopener noreferrer">
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>
                        <span>hello@keiththompson.studio</span>
                    </a>
                </address>
            </ContactWrapper>
        )
    }
}
